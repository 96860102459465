<template>
  <div class="detail-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.detail-content{
  display: flex;
  flex-wrap:wrap;
  padding-left:15px;
  margin-bottom:15px;
  div{
    min-width:33%;
    margin-top:15px;
    color:#666;
    font-size:14px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      width:136px;
      padding:5px;
      color:#000;
      font-size:14px;
      text-align: right;
      border-radius:5px;
      &:hover{
        opacity: 0.9;
        cursor:pointer;
      }
    }
    .warning{
      color:#F7BA2A;
    }
    .danger{
      color: #FF4949;
    }
  }
  .width-flex{
    flex:1
  }
  .width-all{
    width:100%;
  }
}
</style>
